import Rails from '@rails/ujs';

const submitFormOnChange = () => {
  const inputs = document.querySelectorAll('.submit-form-on-change');

  if(inputs && inputs.length > 0){
    for(let i = 0; i < inputs.length; i++){
      const input = inputs[i];
      input.addEventListener('change', () => submitForm(input));

    }
  }
}


const submitForm = (element) => {
  const form = document.querySelector('form[data-remote]');
  if(form) Rails.fire(form, 'submit')
}


document.addEventListener('turbolinks:load', submitFormOnChange)
