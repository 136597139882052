import React from 'react';

const NewPinForm = (props) => (
  <div
    className={`modal-container blur-bg flex-container align-center-middle toggle-trans ${props.visible ? '' : 'trans'}`}
  >
    <div
      id="form-container"
      className="light-gray-bg lp xlpl xlpr border-radius"
    >
      <form
        onSubmit={props.handleSubmit}
      >
        {props.showError &&
          <React.Fragment>
            <h5 className='red bold'>Oops! Something went wrong:</h5>

            <ul
              className='nm no-style-list'
            >
              {props.formErrors}
            </ul>
          </React.Fragment>
        }

        <label
          htmlFor="body"
        >
          What do you want to say?
        </label>
        <h5
          className='smb italic'
        >
          300 characters max. If you need to say more, click Cancel and write a comment below.
        </h5>

        <div className="relative">
          <textarea
            id='pin-comment-field'
            type="text"
            value={props.body}
            name='body'
            onChange={props.handleChange}
            className='xsmb'
            rows={4}
            cols={50}
            maxLength={400}
          />
          <h5
            className={`absolute bottom right smr ${props.body.length > 250 ?  'red bold' : ''}`}
          >
            {300 - props.body.length}
          </h5>
        </div>




        <h5
          className={`toggle-trans red ${props.warn ? '' : 'trans'} smb text-center bold`}
        >
          {props.warn}
        </h5>

        <input type="submit" value='Post' className='button expanded blue-bg white round-border smb'/>
      </form>

      <button
        className="button expanded yellow-bg black round-border"
        onClick={props.handleCancel}
      >
        Cancel
      </button>

    </div>

  </div>
);

export default NewPinForm;
