// code based on https://github.com/stevepolitodesign/rails-nested-form-example

const removeFields = () => {

  document.addEventListener("click", (e) => {
    if (e.target && e.target.className == "remove_fields") {
      handleClick(e.target, e);
    }
  });
}

const handleClick = (link, e) =>  {
  if (!link || !e) return;
  e.preventDefault();
  let fieldParent = link.closest(".nested-fields");
  let deleteField = fieldParent
    ? fieldParent.querySelector('input[type="hidden"]')
    : null;
  if (deleteField) {
    deleteField.value = 1;
    fieldParent.style.display = "none";
  }
}

document.addEventListener("turbolinks:load", () => new removeFields());