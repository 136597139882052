// code based on https://github.com/stevepolitodesign/rails-nested-form-example

const addFields = () => {
  links = document.querySelectorAll(".add_fields");

   if (links.length > 0 ){
    links.forEach((link) => {
      link.addEventListener("click", (e) => {
        handleClick(link, e);
      })
    })
  }
}

const handleClick = (link, e) =>  {
  if (!link || !e) return;
  e.preventDefault();
  let time = new Date().getTime();
  let linkId = link.dataset.id;
  let regexp = linkId ? new RegExp(linkId, "g") : null;
  let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
  newFields ? link.insertAdjacentHTML("beforebegin", newFields) : null;
}

document.addEventListener("turbolinks:load", () => new addFields());