// Assign the setDialog event to all elements with .open-dialog class
const openDialog = () => {

  const elements = document.querySelectorAll('.open-dialog');
  const elementsArray = [...elements];

  elementsArray.forEach(el => {
    el.addEventListener(
      'click',

      // Open an alert with elements dialog data attribute
      (e) => {
        e.preventDefault();
        const dialog = el.getAttribute('data-dialog');
        if(dialog) alert(dialog);
      }
    )
  })
}

document.addEventListener('turbolinks:load', openDialog);
