// TODO refactor into DIALOG compoents

import React from 'react';

const NEW_MESSAGE = 'Click the Image to place your Pin'
const UPDATE_MESSAGE = 'Move it by clicking elsewhere.'
const FINISH_MESSAGE = 'When you\'re happy, click Save.'

const TEXT_CLASSES = 'nm white bold blur-bg darker-gray-trans-bg mp border-radius text-center'

const AddPinDialog = (props) => (
  <div
    className={`fixed ignore-click toggle-trans ${props.visible ? '' : 'trans'}`}
    style={{top: '25%', left: '10%' }}
  >
    <h3 className={TEXT_CLASSES + ' xlmb'}>
      {props.updateMessage ? UPDATE_MESSAGE : NEW_MESSAGE}
    </h3>

    {props.updateMessage &&
      <h3 className={TEXT_CLASSES}>
        {FINISH_MESSAGE}
      </h3>
    }

  </div>
);

export default AddPinDialog;
