import * as vis from './visibility';

// written by Stephen Murdoch
// taken from Link Up West Dunbartonshire

var triggerFileAttachedEvent = function(target) {
  triggerCustomEvent(target, "direct-uploads:file-attached", "A file was attached to a file input");
}

var triggerCustomEvent = function(target, name, message) {
  var e = new CustomEvent(name,
    {
      detail: {
        message: message,
        file: target.files[0]
      },
      bubbles: true,
      cancelable: true
    });

  target.dispatchEvent(e);
}

document.addEventListener('change', function(e) {
  var target = e.target;

  if(target && target.tagName == 'INPUT' && target.type == 'file' && target.dataset.directUploadUrl) {
    triggerFileAttachedEvent(target);
  }
});


// Original version by Stephen Murdoch. Modifed by Jim Bevington
// (Original taken from Link Up West Dunbartonshire)


const FILE_DATA = {
  image: {
    limitString: '10MB',
    limit: 10000000,
    quantity: 'MB',
    types: [
      'image/png',
      'image/jpeg',
      'image/gif'
    ],
    typeString: 'png, jpeg or gif'
  },
  audio: {
    limitString: '500MB',
    limit: 500000000,
    quantity: 'MB',
    types: [
      'audio/mp3',
      'audio/mp4',
      'audio/m4a',
      'audio/mpeg',
      'audio/x-wav',
      'audio/wav',
      'audio/aac',
      'audio/flac',
    ],
    typeString: 'mp3, m4a, x-wav, wav, aac or flac'
  },
  video: {
    limitString: '1GB',
    limit: 1000000000,
    quantity: 'GB',
    types: [
      'video/mp4',
      'video/avi',
      'video/x-msvideo',
      'video/wmv',
      'video/x-ms-wmv',
      'video/flv',
      'video/x-flv',
      'video/mov',
      'video/quicktime',
      'video/ogg',
    ],
    typeString: 'mp4, avi, wmv, flv, mov or ogg'
  },
  application: {
    limitString: '10MB',
    limit: 10000000,
    quantity: 'MB',
    types: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      'application/vnd.oasis.opendocument.text'
    ],
    typeString: 'pdf, doc, docx, rtf or odt'
  }
}


const FILE_TYPES = { v: 'video', i: 'image', a: 'audio', t: 'application' }


function validateFile(file) {
  // hideExisting();
  renderPreview(file);
  renderPreviewFile(file);
}

function hideExisting(){
  const existingImage = document.getElementById('existing-image');
  const changeImageButton = document.getElementById('change-image-button');
  if(existingImage && changeImageButton) vis.hide(existingImage)
}

function renderPreview(file) {
  const type = getFileType(file);
  var preview = document.getElementById(`${type}-preview`);
  var html = previewHTML(file);

  preview.innerHTML = html;
}

function getFileType(file){
  for (let k in FILE_TYPES){
    let type = FILE_TYPES[k];
    if(file.type.startsWith(type)){
      return type;
    }
  }

}

function previewHTML(file) {
  var sizeValid = validateSize(file);
  var typeValid = validateType(file);

  return `
    <div id="direct-upload-preview">
      <p class="strong text-center nmb">${previewTitle(sizeValid, typeValid)}</p>

      <ul class="no-bullet nmb mmt">
        ${getPreview(file, sizeValid, typeValid)}
        ${getPreviewSizeAndType(file, sizeValid, typeValid)}
      </ul>
    </div>
  `;
}

function previewTitle(sizeOk, typeOk) {
  if (sizeOk && typeOk) {
    return '<i class="fa fa-check success-color smr"></i> File is valid, and will upload when you click "Save" below';
  } else {
    return '<i class="fa fa-close danger-color smr"></i> Whoops, your file is not valid';
  }
}

function imageHTML(file) {
  return `
    <li>
      <img id="image-preview-thumbnail" class="image-preview-thumbnail stretch border-radius" />
    </li>
  `;
}

function videoHTML(file) {
  return `
    <li>
      <video id="video-preview-thumbnail" class="video-preview-thumbnail stretch border-radius gray-trans-bg full-width" controls>
      </video>
    </li>
   `;
}

function audioHTML(file) {
  return `
    <li>
      <audio id="audio-preview-thumbnail" class="audio-preview-thumbnail stretch border-radius" controls />
    </li>
   `;
}

function textHTML(file) {
  // NOTE - we use 'application-preview-...' here, in line with mime prefix
  return `
    <li>
      <object >
        <embed id="application-preview-thumbnail" />
      </object>
    </li>
   `;
}

function fileHTML(file) {
  const html = {
    image: imageHTML(file),
    video: videoHTML(file),
    audio: audioHTML(file),
    application: textHTML(file),
  }

  const type = getFileType(file);
  return html[type];
}

function getPreview(file, sizeOk, typeOk) {
  var html = '';
  const fileMarkup = fileHTML(file);
  if (sizeOk && typeOk) { html += fileMarkup; }
  return html;
}

function getPreviewSizeAndType(file, sizeOk, typeOk) {
  var html = '';

  if (!sizeOk) { html += sizeHTML(file); }
  if (!typeOk) { html += typeHTML(file); }

  return html;
}

function sizeHTML(file) {
  const type = getFileType(file);
  const { limit, limitString, quantity } = FILE_DATA[type]

  return `
    <li>
      <i class="fa fa-close medium-gray smr"></i>
      File is too big. The maximum is ${limitString}
    </li>
  `;
}

function typeHTML(file) {
  const type = getFileType(file);
  const { typeString } = FILE_DATA[type]
  return `
    <li>
      <i class="fa fa-close medium-gray smr"></i>
      File type is not permitted, must be ${typeString}
    </li>
  `;
}

// NEW
function validateSize(file) {
  const type = getFileType(file);
  const { limit } = FILE_DATA[type]
  return file.size < limit;
}

// NEW
function validateType(file) {
  const type = getFileType(file);
  const { types } = FILE_DATA[type]
  return types.includes(file.type);
}


function renderPreviewFile(file) {
  const type = getFileType(file);
  var div = document.getElementById(`${type}-preview-thumbnail`);

  let action;
  if(type === 'image'){
    action = function() { div.src = reader.result; }
  } else if(type === 'text') {
    // text lives in an <embed>, inside an <object> requiring a data attr
    action = function() {
      div.src = reader.result;
      div.parentElement.data = reader.result;
      div.type = file.type;
    }
  } else {
    // video and audio have same reqs.
    action = function() {
      div.src = reader.result;
      div.type = file.type;
    }
  }

  if (div) {
    var reader = new FileReader();
    reader.onload = action;
    reader.readAsDataURL(file);
  }
}

// written by Stephen Murdoch
// taken from Link Up West Dunbartonshire

addEventListener("direct-uploads:file-attached", event => {
  if(event.detail && event.detail.file) validateFile(event.detail.file);
})

addEventListener("direct-uploads:start", event => {
  if(document.getElementById('upload-progress')){
    document.getElementById('upload-progress').classList.remove('hide');
  }
})

addEventListener("direct-upload:initialize", event => {
  if(event.detail){
    const { target, detail } = event;
    const { id, file } = detail;

    var progressDiv = document.getElementById('upload-progress');

    if(progressDiv){
      progressDiv.insertAdjacentHTML("beforeend", `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="black word-wrap direct-upload__filename">${file.name}</span>
        </div>`);
    }
  }
});


addEventListener("direct-upload:start", event => {
  if(event.detail){
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    if(element) element.classList.remove("direct-upload--pending");
  }
});

addEventListener("direct-upload:progress", event => {
  if(event.detail){
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    if(progressElement) progressElement.style.width = `${progress}%`;

  }
});

addEventListener("direct-upload:error", event => {
  event.preventDefault();
  if(event.detail){
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    if(element){
      element.classList.add("direct-upload--error");
      element.setAttribute("title", error);
    }
  }

});

addEventListener("direct-upload:end", event => {
  if(event.detail){
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add("direct-upload--complete");
  }


});
