import React from 'react';
import Comment from './Comment';

// Render List of Comment components or placeholder

export default function CommentList(props) {

  // Placeholder
  if(props.comments.length === 0){
    return <div className="callout gray text-center lead italic">
      No comments yet
    </div>
  }

  const comments = props.comments.map((comment, index) => (
    <Comment key={index} comment={comment} />
  ))

  return (
    <table className='callout sp'>
      <tbody>
        {comments}
      </tbody>
    </table>
  )
}
