import React, { useState } from "react";
import PropTypes from "prop-types";
import Konva from 'konva';
import {
  Stage,
  Layer,
  Image,
  Star,
  Rect,
  Text,
  Group
} from 'react-konva';
import getToken from '../app/getToken';
import NewPinForm from './NewPinForm';
import PinboardButtons from './PinboardButtons';
// import NumberKey from './NumberKey';
import Pin from './Pin';
import AddPinDialog from './AddPinDialog';
import * as colors from '../app/colors';
import remToPixels from '../app/remToPixels';


const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const TIP_WIDTH = 180;
const LINE_HEIGHT = 20;
const FONT_SIZE = remToPixels(1);


class Pinboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      width: 0,
      height: 0,
      image: null,
      coords: null,
      commentFormVisible: false,
      body: '',
      warn: '',
      pins: [],             // existing pins
      myPins: [],           // pins I have made this session
      id: null,
      showPins: true,
      canAddPins: false,
      addPinDialogVisible: false,
      cursorStyle: 'crosshair',
      showTip: false,
      tipIdx: null,
    };
    this.pinImage = null;
    this.container = React.createRef();
    this.setDimensions = this.setDimensions.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleDragStart = this.handleDragStart.bind(this);
    // this.handleDragEnd = this.handleDragEnd.bind(this);
    this.fetchPins = this.fetchPins.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddPin = this.handleAddPin.bind(this);
    this.submitPin = this.submitPin.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.focus = this.focus.bind(this);
    this.clearTip = this.clearTip.bind(this);
  }


  // Size the canvas with image; add listener to resize with window
  componentDidMount(){
    this.setImageAndHeightRatio();
    window.addEventListener('resize', this.setDimensions);
    this.fetchPins(); // move to after pins
  }


  // Load image and determine heightRatio, then trigger setDimensions
  setImageAndHeightRatio(){
    const img = new window.Image();

    // Store image in state when it loads, then trigger setDimensions
    img.onload = () => {
      this.setState({ image:img }, this.setDimensions)
    }

    // Set the source, triggering onload event ^
    img.src = this.props.pinboard.image;
  }


  // set dimensions based on images actual dimensions
  setDimensions(){
    const { image } = this.state;
    const heightRatio = image.naturalHeight / image.naturalWidth;
    const width = this.container.current.offsetWidth;
    const height = width * heightRatio;
    this.setState({ width:width, height:height, loading:false });
  }


  fetchPins(){
    fetch(`/pinboards/${this.props.pinboard.id}/pins`)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: error });
        else this.setState({ pins: res.data }, this.handleFetchedPins)
      })
      .catch(e => this.setState({ error: e }))
  }


  handleFetchedPins(){
    if(!this.props.active) this.setState({ showPins: true })
  }


  // render Pins, either myPins or
  renderPins(myPins=false){
    const pins = myPins ? this.state.myPins : this.state.pins;
    return pins.map(p => {
      const coords = this.convertXYToCanvasXY({ x:p.x, y:p.y })
      return <Pin
        x={coords.x}
        y={coords.y}
        body={p.body}
        key={`pin-${p.id ? p.id : coords.x }`}
        myPin={myPins}
        setCursor={(style) => this.setState({ cursorStyle: style })}
      />
    })
  }


  // Make image clickable & show Dialog
  handleAddPin(){
    this.setState({ canAddPins:true }, this.showDialog)
  }


  // show the dialog, then start countdown to hide again
  showDialog(){
    this.setState({ addPinDialogVisible:true }, () => {
      setTimeout(() => this.setState({ addPinDialogVisible:false }), 3000)
    })
  }


  // Get clicked coords and set in state.
  // If there are no coords, there must be no pin, so open comment form
  handleImageClick(e){
    if(this.state.canAddPins){
      const coords = this.convertCanvasXYToXY(e);

      // If no coords, it's new, so show form
      // TODO = refactor
      if(!this.state.coords){
        this.setState({ coords: coords, commentFormVisible: true }, () => this.focus('pin-comment-field'));
      } else { // it exists, so just update the coords
        this.setState({ coords: coords });
      }
    }
  }


  // Use click event to determine a 'real' XY coordinate for the pin
  // only works with clicks right now
  convertCanvasXYToXY(e){
    const rect = this.container.current.getBoundingClientRect();
    let xCoord = event.clientX
    let yCoord = event.clientY
    if(e.type === 'tap') {
      xCoord = e.evt.changedTouches[0].clientX;
      yCoord = e.evt.changedTouches[0].clientY;
    }
    // Determines x and y positions on canvas on 0-1000 scale.
    // i.e mouse position minus canvas distance from top & left,
    // divided by the canvas size and scaled to 1000.
    const x = ((xCoord - rect.left) / this.state.width) * 1000;
    const y = ((yCoord - rect.top) / this.state.height) * 1000;
    return { x:x, y:y };
  }


  // Convert 'real' XY to coordinates relative to canvas size
  convertXYToCanvasXY(coords){
    const canvasX = (coords.x / 1000) * this.state.width;
    const canvasY = (coords.y / 1000) * this.state.height;
    return { x:canvasX, y:canvasY };
  }


  handleChange(e){
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }


  // after clicking submit in New Pin form, validate it.
  // If valid, close form, clear warning and show update Dialog
  handleSubmit(e){
    e.preventDefault();
    const warning = this.validate()
    if(warning) this.warn(warning);
    else this.setState({ commentFormVisible:false, warn:''}, this.showDialog)
  }


  validate(){
    if(this.state.body === ''){
      return "You can't post a blank pin. Write something!";
    } else if (this.state.body.length > 300){
      return 'Too long. Max length is 300 characters.';
    } else {
      return '';
    }
  }


  warn(warning){
    this.setState({ warn:warning })
  }


  handleCancel(){
    this.setState({
      coords: null,
      commentFormVisible: false,
      body: '',
      warn: '',
      canAddPins: false,
      cursorStyle: 'crosshair'
    })
  }


  buildPin(){
    const { coords, body } = this.state;
    return {
      x: coords.x,
      y: coords.y,
      comment_attributes: {
        body: body
      }
    }
  }


  submitPin(){
    const pin = this.buildPin();
    let url = `/pinboards/${this.props.pinboard.id}/pins`;
    if(this.state.id) url += `/${this.state.id}`

    fetch(url, {
      method: `${this.state.id ? 'PATCH' : 'POST'}`,
      headers: HEADERS,
      body: JSON.stringify({ pin: pin })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error });
        else {
          this.finishEditing();

          // // show update Dialog if it's a new point, i.e no this.state.id yet
          // const showUpdateDialog = !this.state.id
          //
          // // Clear the Pin form but don't refresh pins.
          // // The new Pin is still editable by dragging.
          // this.setState({
          //   id: res.data.id,
          //   commentFormVisible: false,
          //   warn: '',
          // }, () => { if(showUpdateDialog) this.showDialog() })
        }
      })
      .catch(e => this.setState({ error: e }))
  }


  // When finished, add my new pin to this.state.myPins so it remains visible
  // Then reset the state ready for a new pin to be added.
  finishEditing(){
    const { body, coords } = this.state;
    const finishedPin = { body: body, x: coords.x, y: coords.y };
    const newState = this.state;
    newState.myPins.push(finishedPin);
    newState.id = null;
    newState.coords = null;
    newState.body = '';
    newState.canAddPins = false;
    newState.warn = '';
    newState.cursorStyle = 'crosshair';
    this.setState(newState);
  }


  focus(id){
    const element = document.getElementById(id);
    if(element) element.focus();
  }


  clearTip(){
    this.setState({ showTip:false, tipIdx:null });
  }


  renderTriggers(){
    return this.props.triggers.map((n, i) => {
      const coords = this.convertXYToCanvasXY(n)

      return <Rect
        x={coords.x}
        y={coords.y}
        width={this.state.width * 0.02}
        height={this.state.width * 0.02}
        // fill={colors.mediumGrayTrans} // for dev
        onMouseEnter={() => {
          if(!this.state.showTip) this.setState({ showTip:true, tipIdx:i });
        }}
        onMouseLeave={() => { if(this.state.showTip) this.clearTip() }}
      />
    })
  }


  setTipHeight(text){
    const numLines = Math.ceil((text.length * FONT_SIZE) / TIP_WIDTH);
    return LINE_HEIGHT * numLines;
  }


  renderTip(trigger){
    const tipHeight = this.setTipHeight(trigger.text);

    const coords = this.convertXYToCanvasXY(trigger);

    // Default offset - under trigger
    let offsetX = (TIP_WIDTH * 0.5) * -1;
    let offsetY = 10;

    // Check if right or left bound of tip is off the canvas, move if so.
    if((coords.x + TIP_WIDTH + offsetX) > this.state.width){
      offsetX = TIP_WIDTH * -1; // move left
    } else if((coords.x + offsetX) < 0){
      offsetX = 0; // move right
    }

    // if tip bottom is more than height, move above trigger
    if((coords.y + tipHeight + offsetY) > this.state.height){
      offsetY = (tipHeight + offsetY) * -1;
    }

    // Apply offset
    coords.x += offsetX;
    coords.y += offsetY;

    return <Group
      x={coords.x}
      y={coords.y}
    >
      <Rect
        width={TIP_WIDTH}
        height={tipHeight + 5} // plus padding
        fill={colors.white}
        cornerRadius={5}
        shadowColor="black"
        shadowBlur={5}
        shadowOpacity={0.5}
      />
      <Text
        text={trigger.text}
        fill={colors.black}
        width={TIP_WIDTH * 0.9}
        fontSize={FONT_SIZE}
        align='center'
        offsetY={tipHeight * -0.25}
        offsetX={TIP_WIDTH * -0.05}
      />
    </Group>
  }


  setCursor(){
    let cursor = 'auto';
    if(this.state.canAddPins) cursor = this.state.cursorStyle;
    else if(this.state.showTip) cursor = 'help';
    return cursor;
  }


  // TODO - DRAGGING,
  //        will need to get the current xy of the target,
  //        convert it back into real xy, then update it

  // handleDragStart(e) {
  //   this.scalePinUp(e.target)
  // };
  //
  //
  // handleDragEnd(e) {
  //   this.scalePinDown(e.target);
  //
  //   const coords = this.convertCanvasXYToXY(e, true);
  //   this.setState({ coords: coords }, this.submitPin);
  //   // // update coords in state
  //   // const { x, y } = e.target.attrs;
  //   // this.setState({ coords: { x: x, y: y } })
  // };


  // scalePinUp(pin){
  //   pin.setAttrs({
  //     shadowOffset: {
  //       x: 15,
  //       y: 15
  //     },
  //     scaleX: 1.1,
  //     scaleY: 1.1
  //   });
  // }


  // scalePinDown(pin){
  //   pin.to({
  //     duration: 0.5,
  //     easing: Konva.Easings.ElasticEaseOut,
  //     scaleX: 1,
  //     scaleY: 1,
  //     shadowOffsetX: 5,
  //     shadowOffsetY: 5
  //   });
  // }


  render() {

    const {
      coords,
      commentFormVisible,
      body,
      error,
      warn,
      pins,
      myPins,
      showPins,
      canAddPins,
      addPinDialogVisible,
      id,
      cursorStyle,
      showTip,
      tipIdx,
    } = this.state;


     // Format errors for form
     let formErrors = <li><h5 className='italic red'>{error}</h5></li>

     if(typeof error === 'array') {
       formErrors = error.forEach(e => (
        <li>
          <h5 className='italic white'>
            - {e}
          </h5>
        </li>
       ))
     }

     const pinDimensions = (this.state.height * 0.2) + body.length;

     let newCoords = null;
     if(coords) newCoords = this.convertXYToCanvasXY(coords);

     return (
      <div className="pinboard callout no-border lmt xxsp white-bg relative">

        <PinboardButtons
          active={this.props.active}
          anyPins={pins.length > 0}
          canAddPins={canAddPins}
          showingPins={showPins}
          togglePins={() => this.setState({ showPins: !this.state.showPins })}
          handleAddPin={canAddPins ? () => this.setState({canAddPins:false}) : this.handleAddPin}
          handleToggleHelp
          keyId={this.props.keyId}
        />

        {/* PINBOARD IMAGE */}
        <div
          id='pinboard-image'
          className='relative lmb'
          ref={this.container}
        >

          <Stage
            width={this.state.width}
            height={this.state.height}
            style={{cursor: `${this.setCursor()}`}}
            // style={{cursor: `${canAddPins ? cursorStyle : 'auto'}`}}
          >
            {/* IMAGE */}
            <Layer
              listening={this.state.canAddPins} // ignore clicks if not adding
            >
              <Image
                image={this.state.image}
                width={this.state.width}
                height={this.state.height}
                onClick={this.handleImageClick}
                onTap={this.handleImageClick}
              />
            </Layer>


            {/*  PINS  */}
            <Layer>

              {/* TOOLTIP TRIGGERS */}
              {this.props.triggers && this.renderTriggers()}

              {/* TOOLTIP */}
              {this.props.triggers && showTip && this.renderTip(this.props.triggers[tipIdx])}

              {pins && showPins && this.renderPins()}
              {myPins && this.renderPins(true)}

              {/* NEW PIN */}
              {newCoords &&
                <Pin
                  x={newCoords.x}
                  y={newCoords.y}
                  body={body}
                  isNew
                  setCursor={(style) => this.setState({ cursorStyle: style })}
                  handleSave={this.submitPin}
                  handleCancel={this.handleCancel}
                />
              }
            </Layer>

          </Stage>




          <AddPinDialog
            visible={addPinDialogVisible}
            updateMessage={newCoords} // this will be null (false) if no Pin yet
          />

          <NewPinForm
            visible={commentFormVisible}
            warn={warn}
            handleSubmit={this.handleSubmit}
            showError={error}
            formErrors={formErrors}
            body={body}
            handleChange={this.handleChange}
            handleCancel={this.handleCancel}
          />

        </div>
        {/* END of PINBOARD IMAGE */}
      </div>
    );
  }

}

export default Pinboard;

Pinboard.propTypes = {
  active: PropTypes.bool
}
Pinboard.defaultProps = {
  active: true // is this pinboard accepting new pins?
}
