import { hideAllLayers, showAllLayers, showLayer } from './mapVisibility';
import {Orbit} from 'foundation-sites';
import Foundation from 'foundation-sites';
import mapboxgl from 'mapbox-gl';
import * as vis from './visibility';

// MAP Data

var mapStyle = 'mapbox://styles/icecreamneil/cko1mfsic108417p9knletdog';

var center = [ -5.431525, 56.034430];

var TOKEN = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';


var mapOptions = {
  style: mapStyle,
  zoom: 14,
  center: center,
  pitch: 0,
  // minZoom: 12.1,
  maxZoom: 20,
  // maxBounds: bounds,
  bearing: 0,
  attributionControl: false, // attr control inserted below
}


var setupMap = function(container, options = undefined){
  if(options) combine(mapOptions, options); // Merge new options if provided

  mapOptions.container = container;
  mapboxgl.accessToken = TOKEN;

  var map = new mapboxgl.Map(mapOptions);
  // Setup custom Attribution ctrl
  map.addControl(new mapboxgl.AttributionControl({compact:false}));
  return map;
}


// Combine objects, overriding old props with new
var combine = (orig, nu) => {
  Object.keys(orig).forEach(k => {
    if(nu.hasOwnProperty(k) && nu[k]) orig[k] = nu[k];
  })
}


let map1;
let map2;
let map3;
let container1;
let container2;
let container3;

let questionLayers = [];

var LAYERS = [
  'route-1-lines',
  'route-1-markers',
  'route-1-polygons',
  'route-1-polygons-outline',
  'route-2-lines',
  'route-2-markers',
  'route-2-polygons',
  'route-2-polygons-outline',
  'route-3-lines',
  'route-3-markers',
  'route-3-polygons',
  'route-3-polygons-outline',
];

var getQuestionLayers = (layerToShow) => {
  return LAYERS.filter((layer) => layer.includes(layerToShow));
};

// helper function to update each map with interactions and layer
var updateMapInfo = (map, layer) => {
  setInteraction(map);
  setClickListeners(map);
  hideAllLayers(map, LAYERS);
  showRoutesForQuestion(map, layer);
}

// detect map and setup
var initMap = () => {
  if(mapboxgl.supported()){
    container1 = document.getElementById('map1');
    container2 = document.getElementById('map2');
    container3 = document.getElementById('map3');
    if (container1 && container2 && container3) {
      // map for route-1
      map1 = setupMap('map1');
      map1.addControl(new mapboxgl.NavigationControl(), 'top-left');
      map1.on('load', () => {
        updateMapInfo(map1, 'route-1');
        const help = document.getElementById('map-help');
        if(help){
          vis.fadeIn(help);
          map1.on('zoom', fadeOutHelp)
          map1.on('click', fadeOutHelp)
        }
      });

      // map for route-2
      map2 = setupMap('map2');
      map2.addControl(new mapboxgl.NavigationControl(), 'top-left');
      map2.on('load', () => {
        updateMapInfo(map2, 'route-2')
      });

      // map for route-3
      map3 = setupMap('map3');
      map3.addControl(new mapboxgl.NavigationControl(), 'top-left');
      map3.on('load', () => {
        updateMapInfo(map3, 'route-3')
      });
    }
  }
};


const fadeOutHelp = () => {
  const help = document.getElementById('map-help');
  if(help) vis.fadeOut(help);
  map1.off('zoom', fadeOutHelp);
}


// Enable ScrollZooming with mouse wheel or when CTRL is clicked
// From https://github.com/mapbox/mapbox-gl-js/issues/6884#issuecomment-603230145
var setInteraction = (map) => {
  map.scrollZoom.disable();
  map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

  map.on("wheel", event => {
    if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
      event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
      if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
    } else {
      if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
    }
  });

}

var showRoutesForQuestion = (map,route) => {
  // route params: i.e 'route-1', 'route-2' etc
  questionLayers = getQuestionLayers(route);

  questionLayers.forEach((layer) => {
    showLayer(map, layer);
  });
};

var setClickListeners = (map) => {
  var layers = [
    'route-1-markers',
    'route-1-polygons',
    'route-2-markers',
    'route-2-polygons',
    'route-3-markers',
    'route-3-polygons',
  ];

  layers.forEach((layer) => {
    map.on('click', layer, handleLayerClicked);
    map.on('mouseenter', layer, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layer, () => {
      map.getCanvas().style.cursor = '';
    });
  });
};

var handleLayerClicked = async (e) => {
  var { Name: name, Description: description } = e.features[0].properties;

  // remove  trailing whitespace and remove all dots from name
  var updatedName = name.trim().replace(/\.+/g, '')

  var imagesData = await fetchImages(updatedName).catch((e) => {
    console.log(
      'There has been a problem with your fetch operation: ' + e.message
    );
  });

  update_html_for_map_images(getImagesFrom(imagesData));

  var updatedDescription = removeHtmlTagsFrom(description);


  var innerPopup = document.getElementById('inner-popup');
  if (innerPopup) {
    document.getElementById('header').innerText = name;
    document.getElementById('description').innerHTML = updatedDescription;
    document.getElementById('popup-container').classList.remove('trans');
  }
};

// update map layer on  data-layer attribute change
var mutationObserver = new MutationObserver(function (mutations) {
  mutations.forEach((mutation) => {
    if (mutation.type == 'attributes') {
      var layer = mutation.target.dataset.layer;

      if (layer) {
        hideAllLayers(map, LAYERS);
        showRoutesForQuestion(layer);
      } else {
        showAllLayers(map, LAYERS);
        setClickListeners();
      }
    }
  });
});

async function fetchImages(name) {
  let response = await fetch(`/map_images/${name}`);

  if (!response.ok) {
    var message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  var images = await response.json();
  return images.data;
}

function removeHtmlTagsFrom(string) {
  return string.replace(/<[^>]*>/g, '');
}

function getImagesFrom(data) {
  // data received from the JSON can in the following form:
  // data: "No Map Image Found"
  // or: data: {name: name, images: [{image: img_url}, {image: img,url}, ...]}
  if (typeof data === 'string') {
    return '';
  } else {
    return data;
  }
}

function update_html_for_map_images(data) {
  // clear #image and .orbit-container elements
  // hide #orbit element
  var imgContainer = document.getElementById('image');
  imgContainer.innerHTML = '';

  var orbitContainer = document.querySelector('.orbit-container');
  orbitContainer.innerHTML = '';

  var orbitDiv = document.getElementById('orbit')
  orbitDiv.classList.add('hidden')

  if (data && data.images) {
    if (data.images.length === 1) {
      imgContainer.innerHTML = `<img src="${data.images[0].image}" alt="${data.name}" />`;
    } else if (data.images.length > 1) {
      // create orbit slides that will be appended to .orbit-container
      let imageHtml = '';

      // only one element needs to have .is-active class
      imageHtml += `
          <li class="orbit-slide is-active">
            <figure class="orbit-figure">
              <img class="orbit-image" src="${data.images[0].image}" alt="${data.name}"">
            </figure>
         </li>`

      for (let index = 1; index < data.images.length; index++) {
        imageHtml += `
          <li class="orbit-slide">
            <figure class="orbit-figure">
              <img class="orbit-image" src="${data.images[index].image}" alt="${data.name}"">
            </figure>
         </li>`
      }

      orbitDiv.classList.remove('hidden');
      orbitContainer.innerHTML = imageHtml;

      // in _map_popup.html.haml partial we have #orbit div
      // that contains .orbit-wrapper and .orbit-container
      // since we are adding images dynamically
      // we need to initialize Orbit every time we are doing so
      new Orbit($('#orbit'), {});
      // reinitilize orbit
      Foundation.reInit($('#orbit'), 'orbit');
      // var $orbit = new Foundation.Orbit($('#orbit'), {});

    }
  } else {
    return '';
  }
}

document.addEventListener('turbolinks:load', initMap);
