import React from "react";
import CheckboxQuestion from "./CheckboxQuestion";
import OpenQuestion from "./OpenQuestion";
import RadioQuestion from "./RadioQuestion";
// import RankQuestion from "./RankQuestion";

class SurveyQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextDisabled: true
    }
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCheckboxOtherChange = this.handleCheckboxOtherChange.bind(this);
    this.scanCheckboxesToSetNextDisabled = this.scanCheckboxesToSetNextDisabled.bind(this);
    this.anyCheckboxesChecked = this.anyCheckboxesChecked.bind(this);
  }


  emptyValue(value){
    return value === null || value === '';
  }

  handleRadioChange(e){
    const currentQuestion = this.props.questions[this.props.state.selectedQ];
    if(currentQuestion.required && e.target.value){
      this.setState({nextDisabled:false})
    } else if (currentQuestion.required && this.emptyValue(e.target.value)){
      this.setState({nextDisabled:true})
    }
    this.props.handleChange(e);
  }

  // Return whether any of the checkboxes are checked
  anyCheckboxesChecked(){
    let anyChecked = false;
    const boxes = document.querySelectorAll('.option-checkbox');
    [...boxes].forEach(b => {
      if(b.checked){
        anyChecked = true;
      }
    });
    return anyChecked
  }


  // Check if any checkboxes are checked, enable Next button if so
  scanCheckboxesToSetNextDisabled(){
    this.setState({ nextDisabled: !this.anyCheckboxesChecked() })
  }


  // Check if other text field is empty
  // Check if all checkboxes are unchecked
  // If both true, disable the next button
  handleCheckboxOtherChange(e){
    let textFieldEmpty = true;

    const currentQuestion = this.props.questions[this.props.state.selectedQ];
    if(currentQuestion.required && e.target.value){
      textFieldEmpty = false;
    } else if (currentQuestion.required && this.emptyValue(e.target.value)){
      textFieldEmpty = true;
    }

    this.setState({
      nextDisabled: !this.anyCheckboxesChecked() && textFieldEmpty
    })

    this.props.handleChange(e);
  }

  render() {

    let currentQuestion = this.props.questions[this.props.state.selectedQ];

    const showQuestion = (currentQuestion) => {
      switch (currentQuestion.type) {
        case "checkboxes":
          return (
            <CheckboxQuestion
              attributes={currentQuestion.attributes}
              answers={currentQuestion.answers}
              openQuestion={currentQuestion.openQuestion}
              state={this.props.state}
              handleCheckboxChange={this.props.handleCheckboxChange}
              scanCheckboxesToSetNextDisabled={this.scanCheckboxesToSetNextDisabled}
              handleChange={this.handleCheckboxOtherChange}
              clearField={(name) => {
                if(!this.anyCheckboxesChecked()) this.setState({nextDisabled:true})
                this.props.clearField(name);
              }}
            />
          );

        case "radio_buttons":
          return (
            <RadioQuestion
              attributes={currentQuestion.attributes}
              answers={currentQuestion.answers}
              openQuestion={currentQuestion.openQuestion}
              haveComments={currentQuestion["have_comments"]}
              state={this.props.state}
              handleChange={this.handleRadioChange}
              // handleChange={this.props.handleChange}
            />
          );
        // case "rank":
        //   return (
        //     <RankQuestion
        //       attributes={currentQuestion.attributes}
        //       answers={currentQuestion.answers}
        //       state={this.props.state}
        //       handleChange={this.props.handleChange}
        //     />
        //   );
        default:
          return (
            <OpenQuestion
              attributes={currentQuestion.attributes}
              state={this.props.state}
              handleChange={this.props.handleChange}
            />
          );
      }
    };

    return (
      <div id="pp-container" ref={this.props.ppRef} className="grid-x align-center">
        <div className="cell lmb text-center lead">
          <p>
            Your thoughts
          </p>
        </div>


        <section id="question-wrapper" ref={this.props.questionRef} className='cell small-12 medium-8'>
          <div className="mmb">
            {/* COUNTER */}
            <label className="text-center mm">
              {`${this.props.state.selectedQ + 1}/${
                this.props.questions.length
              }`}
            </label>
            {/* QUESTION */}
            <p className="lmb">{currentQuestion.question}</p>
            <div className="mmb">
              {showQuestion(currentQuestion)}
            </div>
          </div>

          <div className="full-width text-center">
            <button
              id='survey-button'
              className="button"
              // onClick={this.props.handleNext}
              onClick={(e) => {
                this.props.handleNext(e);
                this.setState({nextDisabled:true})
              }}
              disabled={currentQuestion.required && this.state.nextDisabled}
            >
              <h4 className="nm white">Next Question</h4>
            </button>
          </div>



        </section>
      </div>
    );
  }
}

export default SurveyQuestions;
