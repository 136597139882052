// COLORS
// These match with Colors specified in app/assets/stylesheets/_settings.scss

export const orange = '#edd16d';
export const yellow = '#ffee97';
export const lightBrown = '#c8a95c';
export const black = '#313a44';
export const darkGreen = '#369d8a';
export const green = '#85b78b';
export const gray = '#808080';
export const lightGray = '#edeaaea';
export const mediumGrayTrans = 'rgba(202, 202, 202, 0.7)';
export const cream = '#f5f6f0';
export const lightBlue = '#99afcc';
export const darkBlue = '#334766';
export const red = '#ed6b79';
export const transparent = 'rgba(3, 3, 3, 0)';
export const whiteTrans = 'rgba(255,255,255,0.6)';
export const white = '#FFFFFF'
export const darkAlert = '#752424';

export const primary = green;
export const secondary = lightBrown;
export const success = green;
export const warning = orange;
export const alert = red;