import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Success from "./Success";
import ResponseAnswers from "./ResponseAnswers"

const INITIAL_STATE = {
  comment: "",
  score: "",
  layerToShow: "route-1",
  errors: [],
  finished: false,
  selectedQ: 0,
};

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = _.clone(INITIAL_STATE);
    this.handleChange = this.handleChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.hideQuestion = this.hideQuestion.bind(this);
    this.questionRef = React.createRef();
  }

  handleSliderChange(value){
    const newState = this.state;
    newState.score = value;
    this.setState(newState);
    // update rails form
    const element = document.getElementById(`response_answers_attributes_${this.state.selectedQ}_score`)
    element.value = value
  }

  handleChange(e) {
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);

    // update rails form
    const element = document.getElementById(`response_answers_attributes_${this.state.selectedQ}_${e.target.name}`)
    element.value = e.target.value

  }

  handleNext(e) {
    e.preventDefault();

    // clear comment and score in state
    this.setState({
    comment: "",
    score: ""
   })

    // check if any more questions
    if (!this.state.finished) {
      this.hideQuestion();
      const next = this.state.selectedQ + 1;
      next === this.props.questions.length
        ? this.finish()
        : this.nextQuestion(next);
    }
  }

  // Add or Remove .transparent class from the Question container
  hideQuestion(add = true) {
    if (add) {
      this.questionRef.current.classList.add("transparent");
    } else {
      this.questionRef.current.classList.remove("transparent");
    }
  }


  finish() {
    const newState = this.state;
    newState.finished = true;
    newState.layerToShow = ""
    this.setState(newState, () => {
      const success = document.getElementById("success");
      if (success) success.parentElement.parentElement.scrollIntoView(true);
    });

    // submit rails form
    const form = document.getElementById('response-form')
    form.submit()
  }

  // Update the selectedQ in state, fade up Question
  nextQuestion(index) {
    setTimeout(() => {
      this.setState({ selectedQ: index, layerToShow: `route-${index + 1}` }, () => this.hideQuestion(false));
    }, 500);
  }


  render() {
    if (this.state.finished) return (
      <div id="response-component" data-layer={this.state.layerToShow}>
        <Success layer={this.state.layerToShow}/>
      </div>
    )

    const errors = this.state.errors.map((e) => {
      return (
        <li key="error" className="error-message mpb">
          <i className="fa fa-exclamation-triangle smr" />
          {e}
        </li>
      )
    });

    return (
      <div id="response-component" data-layer={this.state.layerToShow}>
        {/* ERRORS */}
        {/* {this.state.errors.length > 0 && (
      console.log("🚀 ~ file: Response.js ~ line 112 ~ Response ~ setTimeout ~ `question-${index}`", `question-${index}`)
      console.log("🚀 ~ file: Response.js ~ line 112 ~ Response ~ setTimeout ~ question-${index}", question-${index})
          <ul className="no-bullet">
            <div className="alert callout">{errors}</div>
          </ul>
        )} */}
        <ResponseAnswers
          handleNext={this.handleNext}
          handleChange={this.handleChange}
          handleSliderChange={this.handleSliderChange}
          questions={this.props.questions}
          state={this.state}
          questionRef={this.questionRef}
          layer={this.state.layerToShow}
        />
      </div>
    );
  }

}

Response.propTypes = {
  questions: PropTypes.array.isRequired,
};
export default Response;
