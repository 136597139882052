import React from "react"
import PropTypes from "prop-types"

const Success = ({layer}) => (
  <div id='success' className="xlpt lmt" data-layer={layer}>
    <h1 className="text-center mmb">Thanks!</h1>

    <p>
      Your feedback will help the design team to refine these options into a concept design that will be shared on this website in June.
    </p>

    <p className='lmb'>
      You can help inform this process by further by completing a short survey about how you move around Lochgilphead.
    </p>

    <a href="/take-the-survey" className='button expanded white large blue-bg'>
      Take the Survey
    </a>

    <a href="/signups/new" className='button expanded white large orange-bg'>
      Sign up to receive email updates
    </a>

  </div>
);

export default Success
