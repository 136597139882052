import React from "react";
import PropTypes from "prop-types";
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from "react";

class RadioQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherVisible: false
    }
  }

  render() {


    return (
      <>
        <div className="cell grid-x mmb">
          {this.props.answers.map((answer, i) => (
            <div
              className='cell small-12 medium-3'
              key={`question_${this.props.state.selectedQ}_answer_${i}`}
            >
              <input
                type="radio"
                id={`question_${this.props.state.selectedQ}_answer_${i}`}
                name={this.props.attributes[0]}
                value={answer}
                onChange={(e) => {
                  this.setState({otherVisible: false}, this.props.handleChange(e))
                }}

              />
              <label
                htmlFor={`question_${
                  this.props.state.selectedQ
                }_answer_${i}`}
              >
                {answer}
              </label>
            </div>
          ))}
        </div>
        {this.props.openQuestion && (
          <div
            className="cell medium-7 row mmb-small-only"
            key={`question_${this.props.state.selectedQ}_answer_${
              this.props.answers.length + 1
            }`}
          >
            <input
              type="radio"
              name={this.props.attributes[0]}
              id={`question_other`}
              onChange={() => {
                this.setState({otherVisible: !this.state.otherVisible})
              }
              }

            />
            <label htmlFor={`question_other`}>Other</label>
            {this.state.otherVisible &&
              <input
              className="inline"
              type="text"
              onChange={this.props.handleChange}
              name={this.props.attributes[0]}
              id="other_input_answer"
              value={this.props.state[this.props.attributes[0]]}
            />
            }

          </div>
        )}
        {/* comment for the question */}
        {this.props.haveComments && <textarea
          id={`question_${this.props.state.selectedQ + 1}_comment`}
          className='smb cell small-12 medium-10'
          type="text"
          name={`question_${this.props.state.selectedQ + 1}_comment`}
          onChange={this.props.handleChange}
          value={this.props.state[`question_${this.props.state.selectedQ + 1}_comment`]}
          placeholder='Tell us what you think...'
          rows={2}
        />}
      </>
    );
  }
}

RadioQuestion.propTypes = {
  attributes: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  openQuestion: PropTypes.bool,
};
export default RadioQuestion;
