// import "core-js/stable";
// import "regenerator-runtime/runtime";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("app/direct_uploads");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)




require('foundation-sites');
$(document).on('turbolinks:load', function() {
    $(function() { $(document).foundation(); });
});

require("app/analytics.js.erb");
require("app/highlightSelectBox")

require("app/pagy.js.erb");

require("app/phase2map.js");
require("app/openDialog");
require("app/scrollTo")
require("app/showOtherTextInput")
require("app/stickyHeader")
require("app/stickySectionNav")
require("app/submitFormOnChange");
require("app/trixEditorOverrides");


require('./nested-forms/addFields')
require('./nested-forms/removeFields')

require("trix")
require("@rails/actiontext")
require('juxtaposejs/build/js/juxtapose');

import 'react-app-polyfill/ie9';
import 'core-js/features/string/';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


require("app/map")
require("app/mapPhaseThree")

import "chartkick/chart.js"


require("app/removeAttachmentGalleryClasses");
