import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

const MyImage = (props) => {
  const { image, ...rest } = props;
  const [img] = useImage(image);
  return <Image
    image={img}
    shadowColor="black"
    shadowBlur={10}
    shadowOpacity={0.7}
    {...rest}
  />;
};

export default MyImage;
