export const scrollToView = (element, additionOffset = 5) => {
  if(!element) return;

  const elementTop = element.getBoundingClientRect().top;
  const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
  const y = element.getBoundingClientRect().top + window.scrollY - headerHeight + additionOffset;

  window.scrollTo({top: y, behavior: 'smooth'});
};

export default scrollToView;