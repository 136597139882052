import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import getToken from "../app/getToken";
import Success from "./Success";
import SurveyQuestions from "./SurveyQuestions";

const INITIAL_STATE = {
  question_1_answer: null,
  question_2_answer: null,
  question_3_answer: null,
  question_1_comment: '',
  question_2_comment: '',
  question_3_comment: '',
  data_confirm: false,
  errors: [],
  finished: false,
  selectedQ: 0,
};

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.submitSurvey = this.submitSurvey.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.hideQuestion = this.hideQuestion.bind(this);
    this.clearField = this.clearField.bind(this);
    this.questionRef = React.createRef();
    this.ppRef = React.createRef();
    this.state = _.clone(INITIAL_STATE);
    this.id = null;
  }

  handleChange(e) {
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  handleCheckboxChange(name, checked) {
    const newState = this.state;
    newState[name] = checked;
    this.setState(newState);
  }

  submitSurvey() {
    const survey = this.getSurveyFromState();

    let url = this.props.url;
    if (this.id) url = url.concat(`/${this.id}`);

    fetch(url, {
      method: `${this.id ? "PATCH" : "POST"}`,
      headers: {
        "X-CSRF-Token": getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ survey: survey }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          this.setState({ errors: res.errors });
        } else {
          if (!this.id) this.id = res.id;

          // clear errors state if populated with data from previous request
          this.setState({ errors: [] });
          this.handleSurveySubmitted();
        }
      });
  }

  getSurveyFromState() {
    const clone = _.clone(this.state);
    delete clone.errors;
    delete clone.finished;
    delete clone.selectedQ;
    return clone;
  }

  handleNext(e) {
    e.preventDefault();
    this.submitSurvey();
  }

  handleSurveySubmitted() {
    if (!this.state.finished) {
      this.hideQuestion();
      const next = this.state.selectedQ + 1;
      next === this.props.questions.length
        ? this.finish()
        : this.nextQuestion(next);
    }
  }

  // Add or Remove .transparent class from the Question container
  hideQuestion(add = true) {
    if (add) {
      this.questionRef.current.classList.add("transparent");
    } else {
      this.questionRef.current.classList.remove("transparent");
    }
  }

  // hide
  finish() {
    const newState = INITIAL_STATE;
    newState.finished = true;
    this.setState(newState, () => {
      const success = document.getElementById("success");
      if (success) success.parentElement.parentElement.scrollIntoView(true);
    });
  }

  // Clearing Radio buttons and Checkboxes
  // Scroll back to Question
  // Update the selectedQ in state, fade up Question
  nextQuestion(index) {
    setTimeout(() => {
      this.clearRadios();
      this.clearCheckboxes();
      // window.scrollTo({ top:0, left:0, behavior: 'smooth' })
      this.setState({ selectedQ: index }, () => this.hideQuestion(false));
    }, 500);
  }

  clearRadios() {
    const radios = document.getElementsByName(
      `question_${this.state.selectedQ + 1}_answer`
    );
    // NOTE - radios is an HTMLCollection, not an Array.
    //        Some browsers do not support forEach on an HTMLCollection.
    //        Therefore, we change it to an array.
    [...radios].forEach((r) => (r.checked = false));
  }

  clearCheckboxes() {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");
    // NOTE - checkboxes is an HTMLCollection, not an Array.
    //        Some browsers do not support forEach on an HTMLCollection.
    //        Therefore, we change it to an array.

    [...checkboxes].forEach((c) => (c.checked = false));
  }


  // Clear a given value in state
  clearField(name){
    const newState = this.state;
    newState[name] = null;
    this.setState(newState);
  }


  render() {
    if (this.state.finished) return <Success />;

    const errors = this.state.errors.map((e) => {
      return (
        <li key="error" className="error-message mpb">
          <i className="fa fa-exclamation-triangle smr" />
          {e}
        </li>
      );
    });

    return (
      <form>
        {/* ERRORS */}
        {this.state.errors.length > 0 && (
          <ul className="no-bullet">
            <div className="alert callout">{errors}</div>
          </ul>
        )}

        <SurveyQuestions
          handleNext={this.handleNext}
          clearField={this.clearField}
          handleChange={this.handleChange}
          handleCheckboxChange={this.handleCheckboxChange}
          questions={this.props.questions}
          ppRef={this.ppRef}
          questionRef={this.questionRef}
          state={this.state}
        />

      </form>
    );
  }
}

Survey.propTypes = {
  url: PropTypes.string,
  data_policy_url: PropTypes.string,
};
export default Survey;
