import React from "react";
import PropTypes from "prop-types";

class CheckboxQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherVisible: false
    }
    this.otherChecked = this.otherChecked.bind(this);
  }


  otherChecked(e){
    if(!e.target.checked){
      this.props.clearField(`question_${this.props.state.selectedQ}_answer`);
    }
  }

  render() {
    return (
      <>
        <div className="cell grid-x align-center mmb">
          {this.props.attributes.map((attr, i) => (
            <div
              key={`${attr}_key`}
              className='cell medium-7'
            >
              <input
                type="checkbox"
                className='option-checkbox'
                id={attr}
                name={attr}
                onChange={(e) => {

                  this.props.handleCheckboxChange(attr, e.target.checked)
                  this.props.scanCheckboxesToSetNextDisabled()
                  
                }}
                
              />
              <label htmlFor={attr}>{this.props.answers[i]}</label>
            </div>
          ))}
        </div>

        {this.props.openQuestion && (
          <div className="cell medium-7 row mmb-small-only">
            <input
              type="checkbox"
              onChange={this.otherChecked}
            />
            <label
              htmlFor={`question_${this.props.state.selectedQ}_answer`}
              className='mmb'
            >
              Other
            </label>

            <input
              className="inline"
              type="text"
              onChange={this.props.handleChange}
              name={`question_${this.props.state.selectedQ}_answer`}
              id={`question_${this.props.state.selectedQ}_answer`}
              value={
                this.props.state[
                  `question_${this.props.state.selectedQ}_answer`
                ]
              }
            />
          </div>
        )}
      </>
    );
  }
}

CheckboxQuestion.propTypes = {
  attributes: PropTypes.array.isRequired,
  answers: PropTypes.array,
  openQuestion: PropTypes.bool,
};
export default CheckboxQuestion;
