import React from "react";
import PropTypes from "prop-types";

class OpenQuestion extends React.Component {
  render() {
    
    return (
      <>
        <textarea
          onChange={this.props.handleChange}
          name={this.props.attributes[0]}
          id={this.props.attributes[0]}
          value={this.props.state[this.props.attributes[0]]}
          cols="30"
          rows="3"
        />
      </>
    );
  }
}

OpenQuestion.propTypes = {
  attributes: PropTypes.array.isRequired,
};
export default OpenQuestion;
