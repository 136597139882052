import React from 'react';
import CommentContext from "./CommentContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons"



const AdminButton = (props) => (
  <CommentContext.Consumer>
    {({context}) => (
      <span>

        <FontAwesomeIcon
          onClick={props.handleEdit}
          icon={faEdit}
          className='hand-on-hover smr'
        />

        <FontAwesomeIcon
          onClick={props.handleDelete}
          icon={faTrash}
          className='hand-on-hover'
        />

      </span>
    )}
  </CommentContext.Consumer>
);

export default AdminButton;

AdminButton.propTypes = {

}
