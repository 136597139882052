import * as colors from './colors'

document.addEventListener("turbolinks:load", () => {
  const selects = document.querySelectorAll('.highlight-selected');

  if(selects && selects.length > 0){
    for(let i = 0; i < selects.length; i++){
      const sel = selects[i];
      sel.addEventListener('change', () => {
        if(sel.value){
          sel.style.backgroundColor = colors.alert;
          sel.style.fontWeight = 700;
        } else {
          sel.style.backgroundColor = 'white';
          sel.style.fontWeight = 300;
        }
      })
    }
  }
})
