import React, { Fragment } from "react"
import PropTypes from "prop-types"

const PinboardButtons = (props) => (
  <div className='row pinboard-btns align-justify'>
    <span>

      {/*  Hide Add/Cancel button if inactive  */}
      {props.active &&
        <button
          className="button pinboard-button xxsmb xxsmr blue-bg small-shadow sp small-grow"
          // disabled={props.canAddPins}
          onClick={props.handleAddPin}
        >
          {props.canAddPins ? 'CANCEL' : 'ADD PIN'}
        </button>
      }

      {props.anyPins &&
        <button
          className="button pinboard-button xxsmb xxsmr green-bg small-shadow sp small-grow"
          onClick={props.togglePins}
        >
          {`${props.showingPins ? 'HIDE' : 'SHOW' } PINS`}
        </button>
      }



    </span>

    <span>
      {/*  Hide Add/Cancel button if inactive  */}
      {props.active &&
        <Fragment>
          <button
            className="button pinboard-button xxsmb blue-bg white small-shadow sp mpl mpr bold show-for-small-only small-grow"
            data-toggle='how-to-use-pinboard-popup'
          >
            ?
          </button>


          <button
            className="button pinboard-button xxsmb blue-bg black small-shadow sp hide-for-small-only small-grow"
            data-toggle='how-to-use-pinboard-popup'
          >
            HOW TO USE
          </button>
        </Fragment>
      }

      {props.keyId &&
        <button
          className="button pinboard-button xxsmb green-bg dark-gray small-shadow sp small-grow"
          data-toggle={props.keyId}
        >
          KEY
        </button>
      }
    </span>



  </div>
)

export default PinboardButtons
