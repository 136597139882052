export const hideAllLayers = (map,layers) => {
  layers.forEach(layer => {

    map.setLayoutProperty(
      `${layer}`,
      'visibility',
      'none'
    );
  })
}

export const showAllLayers = (map, layers) => {
  layers.forEach(layer => {

    map.setLayoutProperty(
      `${layer}`,
      'visibility',
      'visible'
    );
  })
}

export const toggleLayerVisibility = (map, layer) => {
  const visibility = map.getLayoutProperty(`${layer}`, 'visibility') == 'visible' ? 'none' : 'visible'
    map.setLayoutProperty(
      `${layer}`,
      'visibility',
      `${visibility}`
    );
}

export const showLayer = (map, layer) => {
  map.setLayoutProperty(
    `${layer}`,
    'visibility',
    'visible'
  );
}

export const hideLayer = (map, layer) => {
  map.setLayoutProperty(
    `${layer}`,
    'visibility',
    'none'
  );
}