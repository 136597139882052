import React from "react";
import PropTypes from "prop-types";
import Rails from '@rails/ujs';
import _ from "lodash";
import Success from "./Success";
import ResponseAnswers from "./ResponseAnswers"

const INITIAL_STATE = {
  comment: "",
  score: "",
  errors: [],
  finished: false,
};

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = _.clone(INITIAL_STATE);
    this.handleChange = this.handleChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSliderChange(value){
    const newState = this.state;
    newState.score = value;
    this.setState(newState);

    // update rails form
    const element = document.getElementById(`response_answers_attributes_${this.props.questionIndex}_score`)

    element.value = value

  }

  handleChange(e) {
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);

    // update rails form
    const element = document.getElementById(`response_answers_attributes_${this.props.questionIndex}_${e.target.name}`)
    element.value = e.target.value

  }

  handleSubmit(e) {
    e.preventDefault();

    const form = document.getElementById('response-form')
    Rails.fire(form, 'submit')

    // clear comment and score in state
    this.setState({
      comment: "",
      score: "",
      finished: true
     })
  }


  render() {
    if (this.state.finished) return (
      <div id="response-component" >
        <Success />
      </div>
    )

    const errors = this.state.errors.map((e) => {
      return (
        <li key="error" className="error-message mpb">
          <i className="fa fa-exclamation-triangle smr" />
          {e}
        </li>
      )
    });

    return (
      <div id="response-component" >
        <ResponseAnswers
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleSliderChange={this.handleSliderChange}
          questions={this.props.questions}
          questionIndex={this.props.questionIndex}
          state={this.state}
        />
      </div>
    );
  }

}

Response.propTypes = {
  questions: PropTypes.array.isRequired,
  questionIndex: PropTypes.number.isRequired,
};
export default Response;
