import React, { useState, Fragment } from 'react';
import {
  Stage,
  Layer,
  Image,
  Rect,
  Circle,
  Text,
  Group
} from 'react-konva';
import MyImage from './MyImage';
import * as colors from '../app/colors';

const AWS = 'https://blaeloch.s3.eu-west-2.amazonaws.com/'
const NEW_IMAGE = `${AWS}colour-small-flick.png`
const OLD_IMAGE = `${AWS}alt-colour-small-flick.png`
const MIN_IMAGE = `${AWS}minimized-post-it.png`
const UP = `${AWS}up-chev-min.png`
const DOWN = `${AWS}down-chev-min.png`
const CLOSE = `${AWS}close.png`


const Pin = (props) => {

  const [min, setMin] = useState(false);
  const [show, setShow] = useState(true);

  const {
    x,
    y,
    body,
    isNew,
    setCursor,
    handleSave,
    handleCancel,
    ...rest
  } = props;

  const PIN_SIZE = 90 + (body.length * 0.5);
  const OFFSET_X = PIN_SIZE * 0.48;
  const OFFSET_Y = PIN_SIZE * 0.1666666666666;

  let img = NEW_IMAGE;
  if(!isNew){
    min ? img = MIN_IMAGE : img = OLD_IMAGE;
  }

  if(!show) return null;

  return (
    <Group
      x={x}
      y={y}
      // clip={{
      //     x: OFFSET_X * -1.5,
      //     y: OFFSET_Y * -1.5,
      //     width: PIN_SIZE * 1.5,
      //     height: PIN_SIZE * 0.5,
      //   }}
      {...rest}
    >

      {/*  PIN  */}
      <MyImage
        image={img}
        offsetX={OFFSET_X}
        offsetY={OFFSET_Y}
        width={PIN_SIZE}
        height={min ? PIN_SIZE * 0.3705 : PIN_SIZE}
        listening={false} // ignore clicks so other pins can be placed on top
      />

      {/*  TEXT  */}
      {!min &&
        <Text
          text={body}
          fill={colors.black}
          align='center'
          offsetX={PIN_SIZE * 0.38}
          offsetY={PIN_SIZE * -0.15}
          width={PIN_SIZE * 0.75}
          listening={false} // ignore clicks so other pins can be placed on top
        />
      }


      {isNew &&
        <React.Fragment>
          {/* SAVE */}
          <Group
            width={50}
            offsetX={PIN_SIZE * 0.55}
            offsetY={PIN_SIZE * -0.85}
            onMouseEnter={() => setCursor('pointer')}
            onMouseLeave={() => setCursor('crosshair')}
            onClick={handleSave}
            onTap={handleSave}
          >
            <Rect
              width={50}
              height={20}
              fill={colors.darkGreen}
              cornerRadius={5}
              shadowColor="black"
              shadowBlur={5}
              shadowOpacity={0.5}
            />

            <Text
              text={'Save'}
              fill='white'

              width={50}
              align='center'
              offsetY={-4}
            />
          </Group>


          {/* CANCEL */}
          <Group
            width={50}
            offsetX={PIN_SIZE * -0.05}
            offsetY={PIN_SIZE * -0.85}
            onMouseEnter={() => setCursor('pointer')}
            onMouseLeave={() => setCursor('crosshair')}
            // listening={true}
            onClick={handleCancel}
            onTap={handleCancel}
          >
            <Rect
              width={50}
              height={20}
              fill={colors.darkAlert}
              cornerRadius={5}
              shadowColor="black"
              shadowBlur={5}
              shadowOpacity={0.5}
            />

            <Text
              text={'Cancel'}
              fill='white'

              width={50}
              align='center'
              offsetY={-4}
            />
          </Group>
        </React.Fragment>
      }

      {/* MINIMIZE */}
      {!isNew &&
        <Fragment>
          <Group
            width={PIN_SIZE * 0.5}
            height={PIN_SIZE * 0.3}
            offsetX={PIN_SIZE * 0.35}
            offsetY={PIN_SIZE * 0.02}
            onClick={() => setMin(!min)}
            onTap={() => setMin(!min)}
            listening={true}
          >
            <MyImage
              image={min ? DOWN : UP}
              width={16}
              height={8}
              shadowEnabled={false}
            />
          </Group>

          <Group
            width={PIN_SIZE * 0.5}
            height={PIN_SIZE * 0.3}
            offsetX={PIN_SIZE * -0.25}
            offsetY={PIN_SIZE * 0.02}
            onClick={() => setShow(false)}
            onTap={() => setShow(false)}
            listening={true}
          >
          <MyImage
            image={CLOSE}
            width={9}
            height={9}
            shadowEnabled={false}
          />
          </Group>
        </Fragment>
      }

    </Group>
  )
};

export default Pin;
