document.addEventListener("turbolinks:load", function(){
  const proposalPage = document.querySelector('.proposed_route');
  const phase2ResponsesPage = document.querySelector('.phase2_responses')

  if(proposalPage || phase2ResponsesPage){
    // Get the section nav
    var sectionNav = document.getElementById("section-nav");

    // Get the offset position of the sliders container or responses container
    // both of them have .sticky-container class
    var sticky = document.querySelector(".sticky-container").offsetTop;

    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= sticky) {
        sectionNav.classList.add("sticky")
      } else {
        sectionNav.classList.remove("sticky");
      }
    })
  }
})
