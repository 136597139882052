import mapboxgl from 'mapbox-gl';
import * as vis from './visibility';

// MAP Data

var mapStyle = 'mapbox://styles/icecreamneil/ckps5d2p90qpc18mgjf3fohnv';

var center = [ -5.431525, 56.034430];

var TOKEN = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';


var mapOptions = {
  style: mapStyle,
  zoom: 14,
  center: center,
  pitch: 0,
  // minZoom: 12.1,
  maxZoom: 20,
  // maxBounds: bounds,
  bearing: 0,
  attributionControl: false, // attr control inserted below
}


var setupMap = function(container, options = undefined){
  if(options) combine(mapOptions, options); // Merge new options if provided

  mapOptions.container = container;
  mapboxgl.accessToken = TOKEN;

  var map = new mapboxgl.Map(mapOptions);
  // Setup custom Attribution ctrl
  map.addControl(new mapboxgl.AttributionControl({compact:false}));
  return map;
}


// Combine objects, overriding old props with new
var combine = (orig, nu) => {
  Object.keys(orig).forEach(k => {
    if(nu.hasOwnProperty(k) && nu[k]) orig[k] = nu[k];
  })
}


let map;
let container;


// detect map and setup
var initMap = () => {
  if(mapboxgl.supported()){
    container = document.getElementById('map');
    if (container) {
      // map for route-1
      map = setupMap('map');
      map.addControl(new mapboxgl.NavigationControl(), 'top-left');
      map.on('load', () => {
        setInteraction(map);
        setClickListeners(map);
      });

    }
  }
};


const fadeOutHelp = () => {
  const help = document.getElementById('map-help');
  if(help) vis.fadeOut(help);
  map1.off('zoom', fadeOutHelp);
}


// Enable ScrollZooming with mouse wheel or when CTRL is clicked
// From https://github.com/mapbox/mapbox-gl-js/issues/6884#issuecomment-603230145
var setInteraction = (map) => {
  map.scrollZoom.disable();
  map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

  map.on("wheel", event => {
    if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
      event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
      if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
    } else {
      if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
    }
  });

}


var setClickListeners = (map) => {
  var layers = [
    'proposals-points',
    'school-site'
  ];

  layers.forEach((layer) => {

    if(layer == 'school-site'){
      map.on('click', layer, handlePolygonLayerClicked );
    } else {
      map.on('click', layer, handlePointLayerClicked);
    }

    map.on('mouseenter', layer, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layer, () => {
      map.getCanvas().style.cursor = '';
    });
  });
};

var handlePolygonLayerClicked = async (e) => {

  var { Name: name, Description: description } = e.features[0].properties;

  var updatedDescription = removeHtmlTagsFrom(description);

  new mapboxgl.Popup()
    .setLngLat(e.lngLat)
    .setHTML(`
      <div class="spl spr mpt">
      <h3>${name} </h3>
      <p>${updatedDescription}</p>
      </div>

    `)
    .addTo(map);
};

var handlePointLayerClicked = async (e) => {

  var {Description: description } = e.features[0].properties;
  // get ID that can be found in the point description
  const pointID = getPointID(description);

  // scroll to section with point-{ID}
  const element = document.getElementById(`point-${pointID}`);
  if(element){
    element.scrollIntoView({behavior: 'smooth'});
  }
};


const getPointID = (description) => {
  // 24.06.21 description string has following format now:
  // id: 1<br>description:
  const targetString = 'id: '
  const targetStart = description.search(targetString);
  const targetEnd = description.search('<br>');
  const sliceStart = targetStart + targetString.length

  return description.slice(sliceStart, targetEnd);
}

function removeHtmlTagsFrom(string) {
  return string.replace(/<[^>]*>/g, '');
}


document.addEventListener('turbolinks:load', initMap);
