import React from 'react';
import AdminButtons from './AdminButtons';
import CommentContext from "./CommentContext";
import NewComment from "./NewComment";

const Comment = (props) => (
  <CommentContext.Consumer>
    {({context}) => {

      // These vars toggle AdminButtons visibility
      // const belongsToUser = context.user && context.user.id === props.comment.user_id;
      // const canEdit = context.user && (belongsToUser || context.user.admin)

      // const isEditing = props.comment.id === context.editing;



      // Comment markup
      const renderComment = () => (
        <React.Fragment>

          {props.comment.commenter &&
            <h6 className='xsmb bold italic'>{props.comment.commenter}</h6>
          }

            {/*<span className="italic light dark-gray">
              {new Date(props.comment.updated_at).toLocaleDateString()}
            </span>*/}


          <p className='xsmb'>{props.comment.body}</p>

          <div className="row align-justify">
            
            {context.user &&
              <AdminButtons
                handleEdit={() => context.handleEdit(props.comment)}
                handleDelete={() => context.handleDelete(props.comment)}
              />
            }

            {/*<a
              className="underline-on-hover"
              href={`/comments/${props.comment.id}/reports/new`}
            >
              Report
            </a>*/}
          </div>

        </React.Fragment>
      )


      // Form for Editing the comment
      // const renderForm = () => <NewComment
      //   body={context.body}
      //   handleChange={context.handleChange}
      //   handleSubmit={context.handleSubmit}
      //   cancel={context.handleCancelEditing}
      // />


      return (
        <tr>
          <td className='black-border'>
            {/*{isEditing && renderForm()}*/}
            {renderComment()}
          </td>
        </tr>
      )

    }}
  </CommentContext.Consumer>
);

export default Comment;
