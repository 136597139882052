import { update } from 'immutable';
import * as vis from './visibility';

function toggleOtherTextInputOnCheckboxChange(e){

  const textInput = document.getElementById('text_area_questionnaire_other')
  textInput.addEventListener('change', e => updateCheckboxValue(e.target.value))

  if(e.target.checked){
    vis.showById('text_area_questionnaire_other')
    // clean text input value if present;
    // it will be updated again on textInput change
    if(textInput.value) textInput.value = ''
  } else {
    vis.hideById('text_area_questionnaire_other')
    e.target.value = ''
  }
}

function updateCheckboxValue(value){
  document.getElementById('questionnaire_affects_other').value = value
}


document.addEventListener('turbolinks:load', () => {
  const otherCheckboxField = document.getElementById('questionnaire_affects_other')

  if(otherCheckboxField){
    otherCheckboxField.addEventListener('change', e =>  toggleOtherTextInputOnCheckboxChange(e))
  }
})