import React from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {faFrown} from "@fortawesome/free-solid-svg-icons";
import {faLaughBeam} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ResponseAnswers = (props) => {

  let currentQuestion = props.questions[props.questionIndex];

  return(

  <section id="question-wrapper" className='mpt' >
    <label htmlFor="score" className="black text-center mmb">{currentQuestion.name}</label>

    {/* <label htmlFor="score" className="black text-center mmb">
      Would this option encourage you to walk, cycle or wheel more?
      <br/>
      Use the slider to tell us how much - on a scale of 0-100 - it would do so, and write any other comments in the text box.
    </label> */}

      <div
          className='slider-container flex-container align-middle mpl-medium-up mpr-medium-up'
        >

        <FontAwesomeIcon icon={faFrown} size={"2x"} className='spl spr warning slider-icon' />

        <Slider
          style={{
            padding: 0,
            marginRight: '0.7rem',
            marginLeft: '0.7rem',
            marginBottom: '0.7rem'
          }}
          min={0}
          max={100}
          defaultValue={0}
          onChange={props.handleSliderChange}
          handleStyle={[{height: '2rem',width: '2rem'}]}
          trackStyle={[{height: '1rem'}]}
          railStyle={{height: '1rem'}}
          value={props.state.score}
        />


        <FontAwesomeIcon icon={faLaughBeam} size={"2x"} className='spl spr green slider-icon' />


      </div>

      <h3
        className='text-center primary slider-answer mmb'>{props.state.score}</h3>


    <textarea
      className='xlmb'
      placeholder='Add any other comments here...'
      onChange={props.handleChange}
      value={props.state.comment}
      name="comment"
      id="comment"
      rows="2"
    />

    {/*<button
      className="button small primary-bg large white nmb expanded"
      onClick={props.handleSubmit}
    >
      Submit
    </button>*/}

</section>
  )

}
export default ResponseAnswers;
