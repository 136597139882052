
import mapboxgl from 'mapbox-gl';
import {scrollToView} from './scrollToView';

// MAP Data

var mapStyle = 'mapbox://styles/icecreamneil/clmrpyr8p028801qubwbc80wb';

var center = [ -5.431525, 56.034430];

var TOKEN = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';


var mapOptions = {
  style: mapStyle,
  zoom: 14,
  center: center,
  pitch: 0,
  // minZoom: 12.1,
  maxZoom: 20,
  // maxBounds: bounds,
  bearing: 0,
  attributionControl: false, // attr control inserted below
  // add zoom and rotation controls to the map.
  scrollZoom: false,
  dragRotate: false,
  
}


var setupMap = function(container, options = undefined){
  if(options) combine(mapOptions, options); // Merge new options if provided

  mapOptions.container = container;
  mapboxgl.accessToken = TOKEN;

  var map = new mapboxgl.Map(mapOptions);
  // Setup custom Attribution ctrl
  map.addControl(new mapboxgl.NavigationControl() , 'bottom-right');
  return map;
}

function initMap() {
  if(!mapboxgl.supported()) return;

  // Setup map
  let container = document.getElementById('map-phase-three');
  if(!container) return;

  let map = setupMap(container);

  map.on('load', () => {
    // adding click event on the lgp-points layer
    map.on('click', function (e) {
      let features = map.queryRenderedFeatures(e.point, { layers: ['lgp-points'] });
      if (!features.length) {
        return;
      }
      let feature = features[0];
      let name = feature.properties.name.split(' ').join('-').toLowerCase();
      let section  = document.getElementById(`section-${name}`);
      // scroll into view, but exclude the navbar height
      scrollToView(section);
    });
  });


  
}

document.addEventListener('turbolinks:load', initMap);
